/* Custom Scripts
 */

// Buttons
const buttons = () => {
  // Button: Back to top
  const backLink = document.querySelector('.back');

  backLink.addEventListener('click', function (event) {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  // Button: Burger Menu Trigger
  const burgerLink = document.querySelector('.burger');
  const top_bar = document.querySelector('.top-bar');
  const nav = document.querySelector('nav');

  burgerLink.addEventListener('click', function (event) {
    event.preventDefault();

    if (top_bar.classList.contains('open')) {
      top_bar.classList.remove('open');
      top_bar.classList.remove('bg');
      top_bar.classList.remove('-grad-blue');
    } else {
      top_bar.classList.add('open');
      top_bar.classList.add('bg');
      top_bar.classList.add('-grad-blue');
    }

    if (nav.classList.contains('open')) {
      nav.classList.remove('open');
      nav.classList.remove('bg');
      nav.classList.remove('-grad-blue');
      nav.classList.remove('full-width');
    } else {
      nav.classList.add('open');
      nav.classList.add('bg');
      nav.classList.add('-grad-blue');
      nav.classList.add('full-width');
    }
  });

  // Hide mobile nav menu when activated: Add event listeners to child links within nav
  const navLinks = Array.from(nav.getElementsByTagName('a'));
  navLinks.forEach(function (link) {
    link.addEventListener('click', function () {
      nav.classList.remove('open');
      nav.classList.remove('full-width');
    });
  });

  // Contact Form Trigger
  const formTrigger = document.querySelector('.form-trigger');
  const form = document.querySelector('.form');

  formTrigger.addEventListener('click', function (event) {
    event.preventDefault();

    if (form.classList.contains('open')) {
      form.classList.remove('open');
    } else {
      form.classList.add('open');
    }
  });
};

export { buttons };

// Accordions (toggle open/close + close all other accordions when one is opened)
const accordions = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const accordions = document.querySelectorAll('.accordion-heading');

    accordions.forEach((accordion) => {
      accordion.addEventListener('click', () => {
        const currentAccordion = accordion.closest('.accordion-item');
        const content = currentAccordion.querySelector('.accordion-content');

        // Close all other accordions
        accordions.forEach((otherAccordion) => {
          if (otherAccordion !== accordion) {
            const otherAccordionItem =
              otherAccordion.closest('.accordion-item');
            const otherContent =
              otherAccordionItem.querySelector('.accordion-content');

            otherAccordionItem.classList.remove('is-active');
            otherContent.classList.remove('accordion-content-active');
            otherContent.style.maxHeight = '0px';
          }
        });

        // Toggle the clicked accordion
        if (currentAccordion.classList.contains('is-active')) {
          currentAccordion.classList.remove('is-active');
          content.classList.remove('accordion-content-active');
          content.style.maxHeight = '0px';
        } else {
          currentAccordion.classList.add('is-active');
          content.classList.add('accordion-content-active');
          content.style.maxHeight = `${content.scrollHeight + 40}px`;
        }
      });
    });
  });
};
export { accordions };

// CAROUSEL
const carousel = () => {
  const track = document.querySelector('.carousel__track');
  const slides = Array.from(track.children);
  const nextButton = document.querySelector('.carousel__button.-right');
  const prevButton = document.querySelector('.carousel__button.-left');
  const dotsNav = document.querySelector('.carousel__nav');
  const dots = Array.from(dotsNav.children);
  const slideWidth = slides[0].getBoundingClientRect().width;
  const setSlidePosition = (slide, index) => {
    slide.style.left = slideWidth * index + 'px';
  };

  slides.forEach(setSlidePosition);

  const moveToSlide = (track, currentSlide, targetSlide) => {
    track.style.transform = 'translateX(-' + targetSlide.style.left + ')';
    currentSlide.classList.remove('current-slide');
    targetSlide.classList.add('current-slide');
  };

  const updateDots = (currentDot, targetDot) => {
    currentDot.classList.remove('current-slide');
    targetDot.classList.add('current-slide');
  };

  const hideShowArrows = (slides, prevButton, nextButton, targetIndex) => {
    if (targetIndex === 0) {
      prevButton.classList.add('is-hidden');
      nextButton.classList, remove('is-hidden');
    } else if (targetIndex === slides.length - 1) {
      prevButton.classList.remove('is-hidden');
      nextButton.classList.add('is-hidden');
    } else {
      prevButton.classList.remove('is-hidden');
      nextButton.classList.remove('is-hidden');
    }
  };

  // click left -> move slides to the left
  prevButton.addEventListener('click', (e) => {
    const currentSlide = track.querySelector('.current-slide');
    const prevSlide = currentSlide.previousElementSibling;
    const currentDot = dotsNav.querySelector('.current-slide');
    const prevDot = currentDot.previousElementSibling;
    const prevIndex = slides.findIndex((slide) => slide === prevSlide);

    moveToSlide(track, currentSlide, prevSlide);
    updateDots(currentDot, prevDot);
    hideShowArrows(slides, prevButton, nextButton, prevIndex);
  });

  // click right -> move slides to the right
  nextButton.addEventListener('click', (e) => {
    const currentSlide = track.querySelector('.current-slide');
    const nextSlide = currentSlide.nextElementSibling;
    const currentDot = dotsNav.querySelector('.current-slide');
    const nextDot = currentDot.nextElementSibling;
    const nextIndex = slides.findIndex((slide) => slide === nextSlide);

    moveToSlide(track, currentSlide, nextSlide);
    updateDots(currentDot, nextDot);
    hideShowArrows(slides, prevButton, nextButton, nextIndex);
  });

  // click indicators -> move slides left or right
  dotsNav.addEventListener('click', (e) => {
    const targetDot = e.target.closest('button');
    if (!targetDot) return;

    const currentSlide = track.querySelector('.current-slide');
    const currentDot = dotsNav.querySelector('.current-slide');
    const targetIndex = dots.findIndex((dot) => dot === targetDot);
    const targetSlide = slides[targetIndex];

    moveToSlide(track, currentSlide, targetSlide);
    updateDots(currentDot, targetDot);
    hideShowArrows(slides, prevButton, nextButton, targetIndex);
  });
};

export { carousel };

// CAROUSEL
const cf7SuccessPage = () => {
  document.addEventListener(
    'wpcf7mailsent',
    function (event) {
      var thankyouURL = document.getElementById('thankyouURL').value;
      location = thankyouURL;
    },
    false
  );
};
export { cf7SuccessPage };
// console.log(carousel);
// alert('test!!!');
