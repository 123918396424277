/* SCRIPTS IMPORT CONTROL
Any custom built scripts from /custom or third - party library scripts from /lib must be listed here
This enables modular control of what is merged and minified, or deactivated if currently not needed.
*/

// Buttons
import { buttons } from './custom/custom';
buttons();

// Accordions
import { accordions } from './custom/custom';
accordions();

// Carousel
import { carousel } from './custom/custom';
carousel();

// Contact Form 7 Success Page
// import { cf7SuccessPage } from './custom/custom';
// cf7SuccessPage();
